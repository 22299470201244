import './App.css';
const moment = require('moment')

function App() {
  
  function _getDaysSinceAmandaWasBorn() {
    var birthDate = moment([1994, 8, 20]);
    var today = moment();
    return today.diff(birthDate, 'days')
  }

  return (
    <div class="amandaBirthdayApp container">
      <h5 className='sub'>Amanda föddes för</h5>
      <h1>{_getDaysSinceAmandaWasBorn()}</h1>
      <h5 className='sub'>dagar sedan</h5> 
    </div>
  );
}

export default App;
